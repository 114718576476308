/******
 * UI
 **/
body {
	font-family: Roboto;
	font-size: 	14px;
}

a {
	color: #2196F3;
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
	font-family: Poppins;
	margin: 0;
	font-weight: 600;
}

h1 {
	font-size: 32px;
	color: #fff;
	line-height: 100%;
}

h2 {
	font-size: 24px;
	font-weight: 600;
}

.green {
	color: $green;
}

.red {
	color: $red;
}

.orange {
	color: $orange;
}

input, select {
	background: #FFFFFF;
	border: 1px solid #E2E4E9;
	border-radius: 42px;
	padding: 6px 6px 6px 32px;
	outline: none;
}

.field {
	label {
		font-size: 12px;
		color: #666;
		display: block;
		margin-bottom: 2px;
	}
	.radio {
		label {
			font-size: inherit;
			color: inherit;
			display: inline-block;
			cursor: pointer;
		}
	}
	input, select {
		border-radius: 2px;
		padding: 6px;
		font-size: 14px;
	}
}

.checkbox {
	overflow: hidden;
	position: relative;
	top: 1px;

	input {
		position: absolute;
		top: -100px;
		height: 0;
		width: 0;
	}
	span {
		cursor: pointer;
		display: inline-block;
		margin-right: 3px;
		opacity: 0.5;
		transition: 0.2s opacity;

		&:before {
			content: "\e907";
			font-family: icomoon;
		}
		&:hover {
			opacity: 1;
		}
	}
	input:checked ~ span {
		&:before {
			content: "\e908";
		}
		opacity: 1;
	}
}

.radio {
	padding: 6px 0;
	input {
		height: auto !important;
		width: auto !important;
		display: inline-block;
	}
	span {
		display: inline-block;
	}
}

button, .button {
	border-radius: 120px;
	padding: 7px 10px;
	background: $mediumGrey;
	border: none;
	font-weight: 500;
	color: #fff;
	cursor: pointer;
	transition: background 0.2s;
	outline: none;

	&.red {
		background: $red;
		color: #fff;
		&:hover {
			background: darken($red, 10%);
		}
	}

	&.blue {
		background: $blue;
		color: #fff;
		&:hover {
			background: darken($blue, 10%);
		}
	}

	&.transparent {
		background: transparent;
		border: 1px solid $blue;
		color: $blue;

		&:hover {
			background: rgba($blue, 0.05);
		}
	}

	&.green {
		background: $greenButton;
		color: #fff;
		&:hover {
			background: darken($greenButton, 10%);
		}
	}

	&.grey {
		background: $greyButton;
		color: #fff;
		&:hover {
			background: darken($greyButton, 10%);
		}
	}

	&.add {
		&:before {
			content: "\e924";
			font-family: icomoon;
			position: relative;
			margin-right: 5px;
			margin-left: 2px;
			top: 1px;
			font-size: 13px;
		}
	}
}

.table-wrapper table {
	width: 100%;
	border-collapse: collapse;

	thead {
		td {
			padding: 18px 10px;
			font-size: 14px;
			color: $mediumGrey;

			i:before {
				font-size: 16px;
				position: relative;
				top: 2px;
			}

			&.sort-icon {
				position: relative;

				span {
					position: relative;
					left: 6px;
					cursor: pointer;

					&:after {
						content: '';
						position: absolute;
						background-image: url(images/sorting-icon.png);
						background-size: contain;
						background-repeat: no-repeat;
						height: 8px;
						width: 12px;
						transform: translateY(-50%);
						top: 50%;
					}
				}
			}
		}
	}

	tbody {
		tr {
			cursor: pointer;
			border-top: 1px solid #E2E4E9;
			transition: 0.3s background-color;
			position: relative;

			&:first-child {
				border-top: none;
			}

			&:hover {
				background-color: $tableHover;
			}
		}
		td {
			padding: 14px 12px;
		}
	}

	td {
		&.left {
			text-align: left;
		}
		&.center {
			text-align: center;
		}
		&.right {
			text-align: right;
		}
		&:first-child {
			padding-left: 20px;
		}
		&:last-child {
			padding-right: 20px;
		}
		&.red {
			color: $red;
		}
		&.green {
			color: $green;
		}
		&.no-wrap {
			white-space: nowrap;
		}

		.icon-trash {
			opacity: 0.5;
			cursor: pointer;
			transition: opacity 0.25s;
			&:hover {
				opacity: 1;
			}
		}
	}
}

.progress {
	background: rgba($blue, 0.1);
	color: $blue;
	border-radius: 3px;
	padding: 2px 5px 2px 22px;
	display: inline-block;
	position: relative;

	i {
		position: absolute;
		top: 3px;
		left: 5px;
		font-size: 15px;
	}

	&.orange {
		background: rgba($orange, 0.1);
		color: $orange;
	}

	&.red {
		background: rgba($red, 0.1);
		color: $red;
	}
}

.clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.empty {
	padding: 60px;
	text-align: center !important;
	color: $mediumGrey;
}

// CSS Loader
.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -40px;
	margin-left: -40px;
	width: 80px;
	height: 80px;
	
	div {
		position: absolute;
		border: 4px solid red;
		opacity: 1;
		border-radius: 50%;
		animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

		&:nth-child(2) {
			animation-delay: -0.5s;
		}
	}
}

// Audio player
.rhap_container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: none;
	padding: 15px 100px;
}

.push-player {
	height: 140px;
}

.icon-play {
	font-size: 20px;
	position: relative;
	top: 3px;
	margin-top: -8px;
	display: inline-block;
}

@keyframes loader {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

